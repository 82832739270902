<template>
	<div>
		<div class="mask" v-if="isShow" @click.stop="hideMask">
			<div @click.stop="" class="mask-main zui-flex-column">
				<div @click="hideMask" class="closeMask">X</div>
				<div class="msg-title">{{ title[titleType] }}</div>
				<div class="msg-body">
					<el-form ref="formRef_edit" :inline="true" label-position="top" label-width="80px" size="mini"
						:model="formData">
						<!-- list30 -->
						<div v-if="title[titleType] == $t('report.desc1')">
							<div v-for="item in formData.list30.length" :key="item">
								<el-input type="hidden" v-model="formData.list30[item-1].id" placeholder="序号">
								</el-input>
								<el-form-item :label="$t('report.th16')">
									<el-input v-model="formData.list30[item-1].itemName"
										:placeholder="$t('report.th16')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th17')">
									<el-input v-model="formData.list30[item-1].itemType"
										:placeholder="$t('report.th17')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th18')">
									<el-input v-model="formData.list30[item-1].totalHours"
										:placeholder="$t('report.th18')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th19')">
									<el-input v-model="formData.list30[item-1].totalCyclesNum"
										:placeholder="$t('report.th19')"></el-input>
								</el-form-item>
							</div>
						</div>
						<!-- list33 -->
						<div v-if="title[titleType] == $t('report.desc3')">
							<div v-for="item in formData.list33.length" :key="item">
								<el-input type="hidden" v-model="formData.list33[item-1].fkOperationId"
									:value="fkOperationId" placeholder="序号"></el-input>
								<el-form-item :label="$t('report.th44')">
							<el-date-picker v-model="formData.list33[item-1].dateDay"
									type="datetime" style="border:none"  value-format="yyyy-MM-dd HH:mm"
										size="mini" :placeholder="$t('report.th44')" @change="calc_day()">
									</el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th45')">
									<el-date-picker v-model="formData.list33[item-1].endDateDay"
										type="datetime" style="border:none"  value-format="yyyy-MM-dd HH:mm"
										size="mini" :placeholder="$t('report.th45')" @change="calc_day()">
									</el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.label10')">
									<el-input v-model="formData.list33[item-1].startFly"
										:placeholder="$t('report.label10')"></el-input>
								</el-form-item>
								<!-- <el-form-item :label="$t('report.th35')"><el-date-picker v-model="formData.list33[item-1].dateDay" value-format="yyyy-MM-dd" type="date" style="border:none" size="mini" :placeholder="$t('report.th35')" ></el-date-picker></el-form-item> -->
								<el-form-item :label="$t('report.label13')">
									<el-input v-model="formData.list33[item-1].countHours"
										:placeholder="$t('report.label13')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.label12')">
									<el-input v-model="formData.list33[item-1].endFly"
										:placeholder="$t('report.label12')"></el-input>
								</el-form-item>
								<!-- <el-form-item :label="$t('report.label11')">
									<el-input v-model="formData.list33[item-1].betwteenFly"
										:placeholder="$t('report.label11')"></el-input>
								</el-form-item> -->
								<el-form-item :label="$t('report.th29')">
									<el-input type="textarea" v-model="formData.list33[item-1].remark"
										:placeholder="$t('report.th29')"></el-input>
								</el-form-item>
							</div>
						</div>
						<!-- list34 -->
						<div v-if="title[titleType] == $t('report.desc4')">
							<div v-for="item in formData.list34.length" :key="item">
								<el-input type="hidden" v-model="formData.list34[item-1].fkOperationId"
									:value="fkOperationId" placeholder="序号"></el-input>
								<el-form-item :label="$t('report.th44')">
									<el-date-picker v-model="formData.list34[item-1].repairDateBegin"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th44')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th45')">
									<el-date-picker v-model="formData.list34[item-1].repairDateEnd"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th45')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th31')">
									<el-input v-model="formData.list34[item-1].repairLevel"
										:placeholder="$t('report.th31')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th32')">
									<el-input v-model="formData.list34[item-1].repairProject"
										:placeholder="$t('report.th32')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th29')">
									<el-input type="textarea" v-model="formData.list34[item-1].remake"
										:placeholder="$t('report.th29')"></el-input>
								</el-form-item>
							</div>
						</div>
						<!-- list35 -->
						<div v-if="title[titleType] == $t('report.desc5')">
							<div v-for="item in formData.list35.length" :key="item">
								<el-input type="hidden" v-model="formData.list35[item-1].fkOperationId"
									:value="fkOperationId" placeholder="序号"></el-input>
								<el-form-item :label="$t('report.th44')">
									<el-date-picker v-model="formData.list35[item-1].dateDay" value-format="yyyy-MM-dd"
										type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
									</el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th45')">
									<el-date-picker v-model="formData.list35[item-1].endDateDay"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th45')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th5')">
									<el-input v-model="formData.list35[item-1].serialNum"
										:placeholder="$t('report.th5')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th6')">
									<el-input v-model="formData.list35[item-1].planeDescribe"
										:placeholder="$t('report.th6')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th7')">
									<el-input v-model="formData.list35[item-1].progressContent"
										:placeholder="$t('report.th7')"></el-input>
								</el-form-item>
							</div>
						</div>
						<!-- list36 -->
						<div v-if="title[titleType] == $t('report.desc6')">
							<div v-for="item in formData.list36.length" :key="item">
								<el-input type="hidden" v-model="formData.list36[item-1].fkOperationId"
									:value="fkOperationId" placeholder="序号"></el-input>
								<el-form-item :label="$t('report.th44')">
									<el-date-picker v-model="formData.list36[item-1].faultDate"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th44')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th45')">
									<el-date-picker v-model="formData.list36[item-1].endFaultDate"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th45')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th8')">
									<el-input v-model="formData.list36[item-1].level" :placeholder="$t('report.th8')">
									</el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th6')">
									<el-input v-model="formData.list36[item-1].faultDesc"
										:placeholder="$t('report.th6')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th10')">
									<el-input v-model="formData.list36[item-1].faultReason"
										:placeholder="$t('report.th10')"></el-input>
								</el-form-item>
							</div>
						</div>
						<!-- list37 账单  -->
						<div v-if="title[titleType] == $t('report.desc9')">
							<div v-for="item in formData.list37.length" :key="item">
								<el-form-item :label="$t('report.th44')">
									<el-date-picker v-model="formData.list37[item-1].billTime" value-format="yyyy-MM-dd"
										type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
									</el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th45')">
									<el-date-picker v-model="formData.list37[item-1].endBillTime"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th44')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th21')">
									<el-input v-model="formData.list37[item-1].leg" :placeholder="$t('report.th21')">
									</el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th16')">
									<el-input v-model="formData.list37[item-1].project"
										:placeholder="$t('report.th16')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th23')">
									<el-input v-model="formData.list37[item-1].detail" :placeholder="$t('report.th23')">
									</el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th24')">
									<el-input v-model="formData.list37[item-1].amount" :placeholder="$t('report.th24')">
									</el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th27')">
									<el-input v-model="formData.list37[item-1].exchangeRate"
										:placeholder="$t('report.th27')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th26')">
									<el-input v-model="formData.list37[item-1].amountRmb"
										:placeholder="$t('report.th26')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th25')">
									<el-input v-model="formData.list37[item-1].taxRate"
										:placeholder="$t('report.th25')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th28')">
									<el-input v-model="formData.list37[item-1].serviceCharge"
										:placeholder="$t('report.th28')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th29')">
									<el-input type="textarea" v-model="formData.list37[item-1].remark"
										:placeholder="$t('report.th29')"></el-input>
								</el-form-item>
								<el-input type="hidden" v-model="formData.list37[item-1].fkOperationId"
									:value="fkOperationId" placeholder="序号"></el-input>
							</div>
						</div>
						<!-- list38 -->
						<div v-if="title[titleType] == $t('report.desc11')">
							<div v-for="item in formData.list38.length" :key="item">
								<el-input type="hidden" v-model="formData.list38[item-1].fkOperationId"
									:value="fkOperationId" placeholder="序号"></el-input>
								<el-form-item :label="$t('report.th44')">
									<el-date-picker v-model="formData.list38[item-1].periodOfValidity"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th44')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th45')">
									<el-date-picker v-model="formData.list38[item-1].endPeriodOfValidity"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th44')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th16')">
									<el-input v-model="formData.list38[item-1].project"
										:placeholder="$t('report.th16')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th38')">
									<el-input v-model="formData.list38[item-1].partNumberPn"
										:placeholder="$t('report.th38')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th38')">
									<el-input v-model="formData.list38[item-1].partNumberSn"
										:placeholder="$t('report.th38')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th40')">
									<el-input v-model="formData.list38[item-1].inventory"
										:placeholder="$t('report.th40')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th47')">
									<el-input v-model="formData.list38[item-1].amount"
										:placeholder="$t('report.th47')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th29')">
									<el-input type="textarea" v-model="formData.list38[item-1].remark"
										:placeholder="$t('report.th29')"></el-input>
								</el-form-item>
							</div>
						</div>
						<!-- list39 -->
						<div v-if="title[titleType] == $t('report.desc7')">
							<div v-for="item in formData.list39.length" :key="item">
								<el-input type="hidden" v-model="formData.list39[item-1].fkOperationId"
									:value="fkOperationId" placeholder="序号"></el-input>
								<el-form-item :label="$t('report.th44')">
									<el-date-picker v-model="formData.list39[item-1].dateDay" value-format="yyyy-MM-dd"
										type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
									</el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th45')">
									<el-date-picker v-model="formData.list39[item-1].endDateDay"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th44')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th16')">
									<el-input v-model="formData.list39[item-1].monitoringProject"
										:placeholder="$t('report.th16')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th37')">
									<el-input v-model="formData.list39[item-1].monitoringNum"
										:placeholder="$t('report.th37')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th29')">
									<el-input type="textarea" v-model="formData.list39[item-1].remark"
										:placeholder="$t('report.th29')"></el-input>
								</el-form-item>
							</div>
						</div>
						<!-- list40 -->
						<div v-if="title[titleType] == $t('report.desc8')">
							<div v-for="item in formData.list40.length" :key="item">
								<el-input type="hidden" v-model="formData.list40[item-1].fkOperationId"
									:value="fkOperationId" placeholder="序号"></el-input>
								<el-form-item :label="$t('report.th44')">
									<el-date-picker v-model="formData.list40[item-1].otherDate"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th44')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th45')">
									<el-date-picker v-model="formData.list40[item-1].endOtherDate"
										value-format="yyyy-MM-dd" type="date" style="border:none" size="mini"
										:placeholder="$t('report.th44')"></el-date-picker>
								</el-form-item>
								<el-form-item :label="$t('report.th16')">
									<el-input v-model="formData.list40[item-1].otherProject"
										:placeholder="$t('report.th16')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th36')">
									<el-input v-model="formData.list40[item-1].otherContent"
										:placeholder="$t('report.th36')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('report.th29')">
									<el-input type="textarea" v-model="formData.list40[item-1].remark"
										:placeholder="$t('report.th29')"></el-input>
								</el-form-item>
							</div>
						</div>
						<el-form-item class="zui-flex-row zui-center-center">
							<el-button type="primary"  @click="onSubmit">{{ $t("common.submit") }}
							</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import {
		ownerApi
	} from '../../api/api';
	export default {
		components: {},
		props: ['titleType'],
		data() {
			return {
				isShow: false,
				current: 0,
				title: {
					list30: '机身及发动机',
					// list31: '行程概要',
					list32: '行程统计',
					list33: '行程概要',
					list34: '当月维修工作',
					list35: 'AD/SB 完成情况',
					list36: '保留缺陷监控',
					list37: '账单',
					list38: '航材管理',
					list39: '结构损伤监控',
					list40: '其他事项',
				},
				curentChangeData: null,
				// 当前月报ID
				fkOperationId: '',
				formData: {
					list30: [],
					list31: [],
					list32: [],
					list33: [],
					list34: [],
					list35: [],
					list36: [],
					list37: []
				}
			};
		},

		computed: {
			...mapState(['userInfo', 'token', 'isLogin','language'])
		},
		created: function() {
				this.language_ini()
			if (!this.isLogin) {
				this.$message({
					message: this.$t('report.mesg13'),
					showClose: true,
					type: 'error',
					customClass: 'hahaha',
					duration: 2000,
					offset: 20,
					onClose: () => {
						this.$router.push({
							path: '/login'
						});
					}
				});
				return;
			}
		},
		beforeMount: function() {},
		watch:{
			language(){
				this.language_ini()
				this.calc_day()
			}
		},
		methods: {
			...mapMutations(['logOut']),
			language_ini(){
				this.title={
					list30:this.$t('report.desc1'),
					// list32: '行程统计',
					list33:this.$t('report.desc3'),
					list34: this.$t('report.desc4'),
					list35: this.$t('report.desc5'),
					list36: this.$t('report.desc6'),
					list37:this.$t('report.desc9'),
					list38:this.$t('report.desc11'),
					list39: this.$t('report.desc7'),
					list40:this.$t('report.desc8'),
				}
			},
			showMask(data) {
				this.formData = data;
				this.fkOperationId = data.id;
				this.isShow = true;
				// console.log(this.formData, 1111);
				// console.log(this.fkOperationId, 2222);
			},
			hideMask() {
				this.isShow = false;
			},
			onSubmit() {
				var _this = this;
				// console.log(this.formData[this.titleType]);
				ownerApi[this.titleType + 'Edit'](this.formData[this.titleType]).then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.$message({
							message: this.$t('common.submitSuccess'),
							showClose: true,
							type: 'success',
							customClass: 'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								this.hideMask()
								_this.$emit(_this.titleType, _this.fkOperationId)
								_this.$refs.formRef_edit.resetFields();
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});

						// console.log(res.message);
					}
				});
			},
		getHour(s1, s2) {
			var reDate = /\d{4}-\d{1,2}-\d{1,2} /;
			s1 = new Date((reDate.test(s1) ? s1 : '2017-1-1 ' + s1).replace(/-/g, '/'));
			s2 = new Date((reDate.test(s2) ? s2 : '2017-1-1 ' + s2).replace(/-/g, '/'));
			var ms = s2.getTime() - s1.getTime();
			var min = Math.floor(ms / 1000 / 60 % 60)
			var hour = Math.floor(ms / 1000 / 60 / 60);
			if (ms < 0) {		
			return	{h: 0,m: 0}
			}else{
			return {h: hour,m: min}
			}
			
		},
			calc_day() {
		
			if(this.formData.list33[0]){
				let start = this.formData.list33[0].dateDay
				let endDateDay = this.formData.list33[0].endDateDay
			
				if (start && endDateDay) {
			let res = this.getHour(start,endDateDay)
			let str=res.h+this.$t('report.mesg14')+res.m+this.$t('report.mesg15')

			this.formData.list33[0].countHours = str

				}
				}
			},
		}
	};
</script>

<style lang="scss" scoped>
	.mask {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($color: #000000, $alpha: 0.6);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 99;
		box-sizing: border-box;

		// padding: 30px 0;
		// overflow-y: scroll;
		.mask-main {
			// z-index: 10;
			width: 80%;
			// height: 550px;
			border-radius: 4px;
			background-color: white;
			// overflow: hidden;
			position: relative;

			.closeMask {
				width: 20px;
				height: 20px;
				position: absolute;
				background-color: white !important;
				top: 0;
				right: 0;
				// top: -5px;
				// right: -5px;
				font-size: 18px;
				display: flex;
				font-weight: bold;
				justify-content: center;
				align-items: center;
				border-radius: 20px;
				cursor: pointer;
			}

			.msg-title {
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #000000;
				color: white;
				box-shadow: 0px 8px 7px 1px rgba(0, 0, 0, 0.2);
			}

			.msg-body {
				flex: 1;
				overflow-y: scroll;
				width: 100%;
				padding: 5px 10px;
				box-sizing: border-box;
			}
		}
	}

	// 手机适配
	@media screen and (max-width: 960px) {
		.mask {
			.mask-main {
				width: 95%;

				.closeMask {
					width: 20px;
					height: 20px;
					top: -5px;
					right: -5px;
					font-size: 16px;
					border-radius: 30px;
				}

				.msg-title {
					height: 40px;

					ul {
						li {
							.title {
								font-size: 14px;
							}
						}
					}
				}

				.msg-body {
					padding: 15px;

				}
			}
		}


	}

	/deep/ .el-form-item {
		width: 45%;
	}

	/deep/ .el-date-editor {
		width: auto !important;
	}

	/deep/ .el-form--label-top .el-form-item__label {
		padding: 0 !important;
	}

	/deep/ .el-form-item--mini.el-form-item,
	.el-form-item--small.el-form-item {
		margin-bottom: 1px !important;
	}

	/deep/ .el-input__inner {
		color: black !important;
	}
</style>
