<template>
	<div>
		<div class="mask" v-if="isShowAdd" @click.stop="hideMask">
			<div @click.stop="" class="mask-main zui-flex-column">
				<div @click="hideMask" class="closeMask">X</div>
				<div class="msg-title">{{ title[titleType] }}</div>
				<div class="msg-body">
					<el-form ref="formRef_add" :inline="true" label-position="top" label-width="80px" size="mini"
						:model="formData">
						<!-- list30 -->
						<div v-if="title[titleType] == $t('report.desc1')">
							<el-form-item :label="$t('report.th16')">
								<el-input v-model="formData.list30.itemName" :placeholder="$t('report.th16')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th17')">
								<el-input v-model="formData.list30.itemType" :placeholder="$t('report.th17')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th18')">
								<el-input v-model="formData.list30.totalHours" :placeholder="$t('report.th18')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th19')">
								<el-input v-model="formData.list30.totalCyclesNum" :placeholder="$t('report.th19')">
								</el-input>
							</el-form-item>
							<el-input type="hidden" v-model="formData.list30.houstingId" :value="fkOperationId"
								placeholder="序号"></el-input>
						</div>
						<!-- list33 -->
						<div v-if="title[titleType] == $t('report.desc3')">
							<el-form-item :label="$t('report.th44')">
								<el-date-picker v-model="formData.list33.dateDay" value-format="yyyy-MM-dd HH:mm:ss"
									type="datetime" style="border:none" size="mini" :placeholder="$t('report.th44')"
									@change="calc_day()"></el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th45')">
								<el-date-picker v-model="formData.list33.endDateDay" value-format="yyyy-MM-dd HH:mm:ss"
									type="datetime" style="border:none" size="mini" :placeholder="$t('report.th45')"
									@change="calc_day()"></el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.label10')">
								<el-input v-model="formData.list33.startFly" :placeholder="$t('report.label10')">
								</el-input>
							</el-form-item>
							<!-- <el-form-item :label="$t('report.th35')"><el-date-picker v-model="formData.list33.dateDay" value-format="yyyy-MM-dd" type="date" style="border:none" size="mini" :placeholder="$t('report.th35')" ></el-date-picker></el-form-item> -->
							<el-form-item :label="$t('report.label13')">
								<el-input type="text" v-model="formData.list33.countHours"
									:placeholder="$t('report.label13')"></el-input>
							</el-form-item>
							<el-form-item :label="$t('report.label12')">
								<el-input v-model="formData.list33.endFly" :placeholder="$t('report.label12')">
								</el-input>
							</el-form-item>
							<!-- <el-form-item :label="$t('report.label11')"><el-input v-model="formData.list33.betwteenFly" :placeholder="$t('report.label11')"></el-input></el-form-item> -->
							<el-form-item :label="$t('report.th29')">
								<el-input type="textarea" v-model="formData.list33.remark"
									:placeholder="$t('report.th29')"></el-input>
							</el-form-item>
							<el-input type="hidden" v-model="formData.list33.fkOperationId" :value="fkOperationId"
								placeholder="序号"></el-input>
						</div>
						<!-- list34 -->
						<div v-if="title[titleType] == $t('report.desc4')">
							<el-form-item :label="$t('report.th44')">
								<el-date-picker v-model="formData.list34.repairDateBegin" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th45')">
								<el-date-picker v-model="formData.list34.repairDateEnd" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th45')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th31')">
								<el-input v-model="formData.list34.repairLevel" :placeholder="$t('report.th31')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th32')">
								<el-input v-model="formData.list34.repairProject" :placeholder="$t('report.th32')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th29')">
								<el-input type="textarea" v-model="formData.list34.remake"
									:placeholder="$t('report.th29')"></el-input>
							</el-form-item>
							<el-input type="hidden" v-model="formData.list34.fkOperationId" :value="fkOperationId"
								placeholder="序号"></el-input>
						</div>
						<!-- list35 -->
						<div v-if="title[titleType] == $t('report.desc5')">
							<el-form-item :label="$t('report.th44')">
								<el-date-picker v-model="formData.list35.dateDay" value-format="yyyy-MM-dd" type="date"
									style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th45')">
								<el-date-picker v-model="formData.list35.endDateDay" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th45')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th5')">
								<el-input v-model="formData.list35.serialNum" :placeholder="$t('report.th5')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th6')">
								<el-input v-model="formData.list35.planeDescribe" :placeholder="$t('report.th6')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th7')">
								<el-input v-model="formData.list35.progressContent" :placeholder="$t('report.th7')">
								</el-input>
							</el-form-item>
							<el-input type="hidden" v-model="formData.list35.fkOperationId" :value="fkOperationId"
								placeholder="序号"></el-input>
						</div>
						<!-- list36 -->
						<div v-if="title[titleType] == $t('report.desc6')">
							<el-form-item :label="$t('report.th44')">
								<el-date-picker v-model="formData.list36.faultDate" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th45')">
								<el-date-picker v-model="formData.list36.endFaultDate" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th45')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th8')">
								<el-input v-model="formData.list36.level" :placeholder="$t('report.th8')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th6')">
								<el-input v-model="formData.list36.faultDesc" :placeholder="$t('report.th6')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th10')">
								<el-input v-model="formData.list36.faultReason" :placeholder="$t('report.th10')">
								</el-input>
							</el-form-item>
							<el-input type="hidden" v-model="formData.list36.fkOperationId" :value="fkOperationId"
								placeholder="序号"></el-input>
						</div>
						<!-- list37 -->
						<div v-if="title[titleType] == $t('report.desc9')">
							<el-form-item :label="$t('report.th44')">
								<el-date-picker v-model="formData.list37.billTime" value-format="yyyy-MM-dd" type="date"
									style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th45')">
								<el-date-picker v-model="formData.list37.endBillTime" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th21')">
								<el-input v-model="formData.list37.leg" :placeholder="$t('report.th21')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th16')">
								<el-input v-model="formData.list37.project" :placeholder="$t('report.th16')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th23')">
								<el-input v-model="formData.list37.detail" :placeholder="$t('report.th23')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th24')">
								<el-input v-model="formData.list37.amount" :placeholder="$t('report.th24')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th27')">
								<el-input v-model="formData.list37.exchangeRate" :placeholder="$t('report.th27')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th26')">
								<el-input v-model="formData.list37.amountRmb" :placeholder="$t('report.th26')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th25')">
								<el-input v-model="formData.list37.taxRate" :placeholder="$t('report.th25')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th28')">
								<el-input v-model="formData.list37.serviceCharge" :placeholder="$t('report.th28')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th29')">
								<el-input type="textarea" v-model="formData.list37.remark"
									:placeholder="$t('report.th29')"></el-input>
							</el-form-item>
							<el-input type="hidden" v-model="formData.list37.fkOperationId" :value="fkOperationId"
								placeholder="序号"></el-input>
						</div>
						<!-- list38 -->
						<div v-if="title[titleType] == $t('report.desc11')">
							<el-form-item :label="$t('report.th44')">
								<el-date-picker v-model="formData.list38.periodOfValidity" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th45')">
								<el-date-picker v-model="formData.list38.endPeriodOfValidity" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th16')">
								<el-input v-model="formData.list38.project" :placeholder="$t('report.th16')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th38')">
								<el-input v-model="formData.list38.partNumberPn" :placeholder="$t('report.th38')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th39')">
								<el-input v-model="formData.list38.partNumberSn" :placeholder="$t('report.th39')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th40')">
								<el-input v-model="formData.list38.inventory" :placeholder="$t('report.th40')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th47')">
								<el-input v-model="formData.list38.amount" :placeholder="$t('report.th47')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th29')">
								<el-input type="textarea" v-model="formData.list38.remark"
									:placeholder="$t('report.th29')"></el-input>
							</el-form-item>
							<el-input type="hidden" v-model="formData.list38.fkOperationId" :value="fkOperationId"
								placeholder="序号"></el-input>
						</div>
						<!-- list39 -->
						<div v-if="title[titleType] == $t('report.desc7')">
							<el-form-item :label="$t('report.th44')">
								<el-date-picker v-model="formData.list39.dateDay" value-format="yyyy-MM-dd" type="date"
									style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th45')">
								<el-date-picker v-model="formData.list39.endDateDay" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th16')">
								<el-input v-model="formData.list39.monitoringProject" :placeholder="$t('report.th16')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th37')">
								<el-input v-model="formData.list39.monitoringNum" :placeholder="$t('report.th37')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th29')">
								<el-input type="textarea" v-model="formData.list39.remark"
									:placeholder="$t('report.th29')"></el-input>
							</el-form-item>
							<el-input type="hidden" v-model="formData.list39.fkOperationId" :value="fkOperationId"
								placeholder="序号"></el-input>
						</div>
						<!-- list40 -->
						<div v-if="title[titleType] == $t('report.desc8')">
							<el-form-item :label="$t('report.th44')">
								<el-date-picker v-model="formData.list40.otherDate" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th45')">
								<el-date-picker v-model="formData.list40.endOtherDate" value-format="yyyy-MM-dd"
									type="date" style="border:none" size="mini" :placeholder="$t('report.th44')">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="$t('report.th16')">
								<el-input v-model="formData.list40.otherProject" :placeholder="$t('report.th16')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th36')">
								<el-input v-model="formData.list40.otherContent" :placeholder="$t('report.th36')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('report.th29')">
								<el-input type="textarea" v-model="formData.list40.remark"
									:placeholder="$t('report.th29')"></el-input>
							</el-form-item>
							<el-input type="hidden" v-model="formData.list40.fkOperationId" :value="fkOperationId"
								placeholder="序号"></el-input>
						</div>
						<el-form-item class="zui-flex-row zui-center-center">
							<el-button type="primary"  @click="onSubmit">{{ $t("common.submit") }}
							</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import {
		ownerApi
	} from '../../api/api';
	export default {
		components: {},
		props: ['titleType','change_data'],
		data() {
			return {
				isShowAdd: false,
				current: 0,
				title: {
					list30: '机身及发动机',

					// list32: '行程统计',
					list33: '行程概要',
					list34: '当月维修工作',
					list35: 'AD/SB 完成情况',
					list36: '保留缺陷监控',
					list37: '账单',
					list38: '航材管理',
					list39: '结构损伤监控',
					list40: '其他事项',
				},
				curentChangeData: null,
				// 当前月报ID
				fkOperationId: '',
				//当前飞机ID
				planeId: 0,
				formData: {
					list30: {
						"houstingId": "",
						"itemName": "",
						"itemType": "",
						"totalCyclesNum": "",
						"totalHours": ""
					},

					list33: {
						"fkOperationId": "",
						"betwteenFly": "",
						"countHours": 0,
						"dateDay": "",
						"endFly": "",
						"remark": "",
						"startFly": ""
					},
					list34: {
						"fkOperationId": "",
						"repairProject": "",
						"repairLevel": "",
						"repairDateBegin": "",
						"repairDateEnd": "",
						"remark": ""
					},
					list35: {
						"fkOperationId": "",
						"planeDescribe": "",
						"progressContent": "",
						"serialNum": "",
						"endDateDay": "",
						"dateDay": "",
					},
					list36: {
						"fkOperationId": "",
						"faultDate": "",
						"faultDesc": "",
						"faultReason": "",
						"level": 1,
						"endFaultDate": "",
					},
					list37: {
						"fkOperationId": "",
						"amount": 0,
						"amountRmb": 0,
						"billTime": '',
						"endBillTime": '',
						"currentlyOwed": 0,
						"detail": "",
						"exchangeRate": 0,
						"leg": "",
						"payContent": "",
						"project": "",
						"receivable": "",
						"remark": "",
						"serviceCharge": 0,
						"taxRate": 0,
					},
					list38: {
						"fkOperationId": "",
						"project": "",
						"partNumberPn": "",
						"partNumberSn": "",
						"inventory": "",
						"remark": "",
						"periodOfValidity": "",
						"endPeriodOfValidity": "",
					},
					list39: {
						"fkOperationId": "",
						"monitoringNum": "",
						"monitoringProject": "",
						"remark": "",
						"endDateDay": "",
						"dateDay": "",
					},
					list40: {
						"fkOperationId": "",
						"otherDate": "",
						"endOtherDate": "",
						"otherProject": "",
						"otherContent": "",
						"remark": ""
					}
				}


			};
		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin','language'])
		},
		watch:{
			language(){
				this.language_ini()
				this.calc_day()
			},
			change_data(){
				let a ,b
				let fdata=this.formData
				for( a in fdata){
					for( b in fdata[a]){
						fdata[a][b]=""
					}
				}
				//月份切换清空填写的值
				//console.log(this.formData)
			}
		},
		created: function() {
			this.language_ini()
			if (!this.isLogin) {
				this.$message({
					message: this.$t('report.mesg13'),
					showClose: true,
					type: 'error',
					customClass: 'hahaha',
					offset: 20,
					duration: 2000,
					onClose: () => {
						this.$router.push({
							path: '/login'
						});
					}
				});
				return;
			}
		},
		beforeMount: function() {},
		methods: {
			...mapMutations(['logOut']),
			language_ini(){
				this.title={
					list30:this.$t('report.desc1'),
					// list32: '行程统计',
					list33:this.$t('report.desc3'),
					list34: this.$t('report.desc4'),
					list35: this.$t('report.desc5'),
					list36: this.$t('report.desc6'),
					list37:this.$t('report.desc9'),
					list38:this.$t('report.desc11'),
					list39: this.$t('report.desc7'),
					list40:this.$t('report.desc8'),
				}
			},
			showMask(data, planeId = 0, hostingDate = '') {
				this.fkOperationId = data;
				this.planeId = planeId;
				this.isShowAdd = true;
				this.hostingDate = hostingDate
				// console.log(this.fkOperationId);
			},
			hideMask() {

				this.isShowAdd = false;
				// console.log(this.isShowAdd);
			},
			onSubmit() {
				var _this = this;
				var formData1=JSON.parse(JSON.stringify(_this.formData)) 
				this['formData'][this.titleType].fkOperationId = this.fkOperationId;
				if (this.titleType == 'list30') {
					this['formData'][this.titleType].houstingId = this.fkOperationId
				}
				// console.log(this['formData'][this.titleType]);
				ownerApi[this.titleType + 'Add'](this['formData'][this.titleType], {
					hostingInfoId: this.planeId,
					hostingDate: this.hostingDate
				}).then(res => {
					// console.log(res);
					if (res.code == 200) {
						
						
						this.$message({
							message: this.$t('common.submitSuccess'),
							showClose: true,
							type: 'success',
							customClass: 'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								_this.hideMask()
								if (_this.fkOperationId) {
									_this.$emit(_this.titleType, _this.fkOperationId)
								} else {
									_this.$emit(_this.titleType, res.result)
								}
								_this.$refs.formRef_add.resetFields();
								_this.formData={
									list30: {
										"houstingId": "",
										"itemName": "",
										"itemType": "",
										"totalCyclesNum": "",
										"totalHours": ""
									},
								
									list33: {
										"fkOperationId": "",
										"betwteenFly": "",
										"countHours": 0,
										"dateDay": "",
										"endFly": "",
										"remark": "",
										"startFly": ""
									},
									list34: {
										"fkOperationId": "",
										"repairProject": "",
										"repairLevel": "",
										"repairDateBegin": "",
										"repairDateEnd": "",
										"remark": ""
									},
									list35: {
										"fkOperationId": "",
										"planeDescribe": "",
										"progressContent": "",
										"serialNum": "",
										"endDateDay": "",
										"dateDay": "",
									},
									list36: {
										"fkOperationId": "",
										"faultDate": "",
										"faultDesc": "",
										"faultReason": "",
										"level": 1,
										"endFaultDate": "",
									},
									list37: {
										"fkOperationId": "",
										"amount": 0,
										"amountRmb": 0,
										"billTime": '',
										"endBillTime": '',
										"currentlyOwed": 0,
										"detail": "",
										"exchangeRate": 0,
										"leg": "",
										"payContent": "",
										"project": "",
										"receivable": "",
										"remark": "",
										"serviceCharge": 0,
										"taxRate": 0,
									},
									list38: {
										"fkOperationId": "",
										"project": "",
										"partNumberPn": "",
										"partNumberSn": "",
										"inventory": "",
										"remark": "",
										"periodOfValidity": "",
										"endPeriodOfValidity": "",
									},
									list39: {
										"fkOperationId": "",
										"monitoringNum": "",
										"monitoringProject": "",
										"remark": "",
										"endDateDay": "",
										"dateDay": "",
									},
									list40: {
										"fkOperationId": "",
										"otherDate": "",
										"endOtherDate": "",
										"otherProject": "",
										"otherContent": "",
										"remark": ""
									}
								}
								
								console.log(_this.formData,_this.model,formData1)
								
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			getHour(s1, s2) {
				var reDate = /\d{4}-\d{1,2}-\d{1,2} /;
				s1 = new Date((reDate.test(s1) ? s1 : '2017-1-1 ' + s1).replace(/-/g, '/'));
				s2 = new Date((reDate.test(s2) ? s2 : '2017-1-1 ' + s2).replace(/-/g, '/'));
				var ms = s2.getTime() - s1.getTime();
				var min = Math.floor(ms / 1000 / 60 % 60)
				var hour = Math.floor(ms / 1000 / 60 / 60);
				if (ms < 0) {		
				return	{h: 0,m: 0}
				}else{
				return {h: hour,m: min}
				}
				
			},
			calc_day() {

				let start = this.formData.list33.dateDay
				let endDateDay = this.formData.list33.endDateDay

				if (start && endDateDay) {
	
						let res = this.getHour(start,endDateDay)
						let str=res.h+this.$t('report.mesg14')+res.m+this.$t('report.mesg15')
						this.formData.list33.countHours = str

					
				}

			},
		},

	};
</script>

<style lang="scss" scoped>
	.mask {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($color: #000000, $alpha: 0.6);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 99;

		.mask-main {
			// z-index: 10;
			width: 80%;
			// height: 550px;
			border-radius: 4px;
			background-color: white;
			// overflow: hidden;
			position: relative;

			.closeMask {
				width: 20px;
				height: 20px;
				position: absolute;
				background-color: white !important;
				top: 0;
				right: 0;
				// top: -5px;
				// right: -5px;
				font-size: 18px;
				display: flex;
				font-weight: bold;
				justify-content: center;
				align-items: center;
				border-radius: 20px;
				cursor: pointer;
			}

			.msg-title {
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #000000;
				color: white;
				box-shadow: 0px 8px 7px 1px rgba(0, 0, 0, 0.2);
			}

			.msg-body {
				flex: 1;
				overflow-y: scroll;
				width: 100%;
				padding: 5px 10px;
				box-sizing: border-box;
			}
		}
	}

	// 手机适配
	@media screen and (max-width: 960px) {
		.mask {
			.mask-main {
				width: 95%;

				.closeMask {
					width: 20px;
					height: 20px;
					top: -5px;
					right: -5px;
					font-size: 16px;
					border-radius: 30px;
				}

				.msg-title {
					height: 40px;

					ul {
						li {
							.title {
								font-size: 14px;
							}
						}
					}
				}

				.msg-body {
					padding: 15px;

				}
			}
		}
	}

	/deep/ .el-form-item {
		width: 45%;
	}

	/deep/ .el-date-editor {
		width: auto !important;
	}

	/deep/ .el-form--label-top .el-form-item__label {
		padding: 0 !important;
	}

	/deep/ .el-form-item--mini.el-form-item,
	.el-form-item--small.el-form-item {
		margin-bottom: 1px !important;
	}

	/deep/ .el-input__inner {
		color: black !important;
	}
</style>
