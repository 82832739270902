<template>
	<div class="page">
		<div style="width: 100%">
			<hangar :isPhone="isPhone" :title="$t('report.tab2')" />
		</div>
		<div id="back" v-show="isPhone" @click="back"
			style="z-index: 999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
		<div class="content flex_column" v-if="options.length>0">
			<div class=" flex_column " id="pdfDom" >

				<div class="zui-flex-row zui-center-center changeBox"  v-show="hide_el">
					<el-select :value="detail29_label" size="mini" @change="changeSelect" placeholder="暂无飞机">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="btn222">
					<div class="jinBtn1" @click="getPdfEvn();" v-show="jinBtn1"  :class="language=='en' ? 'en_state' : ''">{{$t('report.mesg8')}}</div>
				</div>

				<div class="theme">
					<div class="title-box">
						<div class="title-text">{{ $t('report.title1') }}</div>
					</div>
				</div>

				<div class="base-info">
					<div class="item-box">
						<div class="item">
							<div class="zui-font-sm "><span class="labelBox" :class="language=='en' ? 'en_state' : ''">{{ $t('report.label1') }}</span>:</div>
							<div class="zui-font-sm zui-flex-1 zui-m-l-10">{{ detail29.registerNum }}</div>
						</div>
						<div class="item">
							<div class="zui-font-sm"><span class="labelBox"  :class="language=='en' ? 'en_state' : ''">{{ $t('report.label2') }}</span>:</div>
							<div class="zui-font-sm zui-flex-1 zui-m-l-10">{{ detail29.localArea  }}
								{{ detail29.localAreaDetail }}
							</div>
						</div>
						<div class="item">
							<div class="zui-font-sm "><span class="labelBox"  :class="language=='en' ? 'en_state' : ''">{{ $t('report.label3') }}</span>:</div>
							<div class="zui-font-sm zui-flex-1 zui-m-l-10">{{ detail29.planeType }}</div>
						</div>
					</div>
					<div class="item-box">
						<div class="item">
							<div class="zui-font-sm"><span class="labelBox"  :class="language=='en' ? 'en_state' : ''">{{ $t('report.label4') }}</span>:</div>
							<div class="zui-font-sm zui-flex-1 zui-m-l-10">{{ detail29.dueTime }}</div>
						</div>
						<div class="item">
							<div class="zui-font-sm "><span class="labelBox"  :class="language=='en' ? 'en_state' : ''">{{ $t('report.label5') }}</span>:</div>
							<div class="zui-font-sm zui-flex-1 zui-m-l-10">{{ detail29.registerArea  }}
								{{ detail29.registerAreaDetail }}
							</div>
						</div>
						<div class="item">
							<div class="zui-font-sm "><span class="labelBox"  :class="language=='en' ? 'en_state' : ''">{{ $t('report.label6') }}</span>:</div>
							<div class="zui-font-sm zui-flex-1 zui-m-l-10">{{ detail29.operationTime }}</div>
						</div>
					</div>
				</div>
				<!-- 机身及发动机 -->
				<div style="margin-bottom: 20px; width: 100%">
					<div class="title">
						{{ $t('report.desc1') }}
						<span class="zui-m-l-20" v-if="userInfo.reportStatus == 1" @click="add('list30')"><img
								class="imgClass" src="../assets/image/add.png" /></span>
					</div>
					<div>
						<el-table :data="table30" border style="width: 100%">
							<el-table-column align="center" header-align="center" prop="value1"
								:label="$t('report.th16')">
								<template slot-scope="scope">{{scope.row.value1 || '-'}}</template>
							</el-table-column>
							<el-table-column align="center" header-align="center" prop="value2"
								:label="$t('report.th17')">
								<template slot-scope="scope">{{scope.row.value2 || '-'}}</template>
							</el-table-column>
							<el-table-column align="center" header-align="center" prop="value3"
								:label="$t('report.th18')">
								<template slot-scope="scope">{{scope.row.value3 || '-'}}</template>
							</el-table-column>
							<el-table-column align="center" header-align="center" prop="value4"
								:label="$t('report.th19')">
								<template slot-scope="scope">{{scope.row.value4 || '-'}}</template>
							</el-table-column>
							<el-table-column v-if="list30.length > 0 && userInfo.reportStatus == 1" width="70"
								align="center" header-align="center" :label="$t('report.operation')">
								<template slot-scope="scope">
									<img @click="edit('list30', scope.$index)" class="imgClass"
										src="../assets/image/12.png" />
									<img @click="del('list30', scope.$index)" class="imgClass"
										src="../assets/image/del.png" />
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="zui-w-100" v-if="isShowReport">
					<div class="theme">
						<div class="title-box">
							<div class="title-text">{{ $t('report.title2') }}</div>
						</div>
					</div>
					<!-- 日期 -->
					<div class="zui-flex zui-center-center" >
						<div class="zui-flex zui-center-center userChange"
							style="background:#000;color:#fff;padding:5px 20px;width:auto;">
							<div class="dateBox">
								<span  v-show="!hide_el">{{searchDate}}</span>
								<el-date-picker ref="date" v-model="searchDate" @change="changeDate"
									value-format="yyyy-MM" :default-value="searchDate" type="month" style="border:none"
									size="mini" placeholder="选择日期"  v-show="hide_el"></el-date-picker>
							</div>
							<img @click="chageDate" class="zui-m-l-10" src="../assets/image/down.png"
								style="width:26px;height:26px" />
						</div>
					</div>
					<!-- 行程概要 -->
					<div style="margin: 20px 0; width: 100%;" >
						<div class="title">
							{{ $t('report.desc3') }}
							<span class="zui-m-l-20" v-if="userInfo.reportStatus == 1" @click="add('list33')"><img
									class="imgClass" src="../assets/image/add.png" /></span>
						</div>
						<!-- <template v-if="list32.length > 0">
					<div class="zui-flex zui-flex-wrap zui-justify-between" v-for="(item, index) in list32" :key="index">
						<div class="zui-flex zui-m-r-20">
							<div class="zui-m-r-10">{{ $t('report.label7') }}:</div>
							<div>{{ item.totalHours }}小时</div>
						</div>
						<div class="zui-flex zui-m-r-20">
							<div class="zui-m-r-10">{{ $t('report.label8') }}:</div>
							<div>{{ item.flightSegment }}次</div>
						</div>
						<div class="zui-flex zui-m-r-20">
							<div class="zui-m-r-10">{{ $t('report.label9') }}:</div>
							<div>{{ item.runtime }}小时</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="zui-flex zui-flex-wrap zui-justify-between">
						{{ $t('report.desc10') }}
					</div>
				</template> -->
						<el-table :data="table33" border style="width: 100%">
							<el-table-column width="240" align="center" header-align="center" prop="value1"
								:label="$t('report.th44')"></el-table-column>
							<el-table-column width="240" align="center" header-align="center" prop="value2"
								:label="$t('report.label10')"></el-table-column>
							<el-table-column width="240" align="center" header-align="center" prop="value3"
								:label="$t('report.th45')"></el-table-column>
							<el-table-column min-width="120" align="center" header-align="center" prop="value4"
								:label="$t('report.label12')"></el-table-column>
							<el-table-column width="240" align="center" header-align="center" prop="value5"
								:label="$t('report.label13')"></el-table-column>
							<el-table-column align="center" header-align="center" prop="value6"
								:label="$t('report.th4')"></el-table-column>
							<el-table-column v-if="list33.length > 0 && userInfo.reportStatus == 1 && jinBtn1"
								width="70" align="center" header-align="center" :label="$t('report.operation')">
								<template slot-scope="scope">
									<img @click="edit('list33', scope.$index)" class="imgClass"
										src="../assets/image/12.png" />
									<img @click="del('list33', scope.$index)" class="imgClass"
										src="../assets/image/del.png" />
								</template>
							</el-table-column>
						</el-table>
						<!-- <div v-if="list33.length > 0" class="zui-w-100" style="overflow-x: scroll;" >
						<div v-for="(item, index) in list33" :key="index" class="zui-flex zui-m-t-20">
							<div class="zui-m-r-10 list33_font" >
								<span v-if="item.dateDay" >{{ item.dateDay }}{{ $t('report.label15') }}</span>
								<span v-if="item.startFly"  class="zui-m-x-10" >{{ $t('report.label10') }}{{ item.startFly }}</span>
								<span v-if="item.betwteenFly" class="zui-m-x-10" >{{ $t('report.label11')}}{{ item.betwteenFly }}</span>
								<span v-if="item.endDateDay" >{{ item.endDateDay }}{{ $t('report.label15') }}</span>
								<span v-if="item.endFly"  class="zui-m-x-10" >{{ $t('report.label12') }}{{ item.endFly }}</span>
								<span v-if="item.countHours" class="zui-m-x-10" >{{ $t('report.label13') }}{{ item.countHours }}{{ $t('report.label14') }}</span>
								<span v-if="item.remark" >{{ $t('report.th4') }} : {{ item.remark }}</span>
							</div>
							
							<span v-if="userInfo.reportStatus == 1" @click="edit('list33', index)"><img class="imgClass" src="../assets/image/12.png" /></span>
							<span v-if="userInfo.reportStatus == 1" @click="del('list33',index)"><img class="imgClass" src="../assets/image/del.png" /></span>
						</div>
					</div> -->
						<div class="underline"></div>
					</div>
					<!-- 航材存储 -->
					<div style="margin-bottom: 20px; width: 100%">
						<div class="title">
							{{ $t('report.desc11') }}
							<span class="zui-m-l-20" v-if="userInfo.reportStatus == 1" @click="add('list38')"><img
									class="imgClass" src="../assets/image/add.png" /></span>
						</div>
						<div>
							<el-table :data="table38" border style="width: 100%">
								<el-table-column width="70" align="center" header-align="center" prop="value1"
									:label="$t('report.th1')"></el-table-column>
								<el-table-column width="240" align="center" header-align="center" prop="value6"
									:label="$t('report.th41')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value2"
									:label="$t('report.th34')"></el-table-column>
								<el-table-column min-width="120" align="center" header-align="center" prop="value3"
									:label="$t('report.th38')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4"
									:label="$t('report.th39')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value5"
									:label="$t('report.th40')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value8"
									:label="$t('report.th47')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value7"
									:label="$t('report.th4')"></el-table-column>
								<el-table-column v-if="list38.length > 0 && userInfo.reportStatus == 1" width="100"
									align="center" header-align="center" :label="$t('report.operation')">
									<template slot-scope="scope">
										<img @click="edit('list38', scope.$index)" class="imgClass"
											src="../assets/image/12.png" />
										<img @click="del('list38', scope.$index)" class="imgClass"
											src="../assets/image/del.png" />
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="underline"></div>
					</div>
					<!-- 当月维修 -->
					<div style="margin-bottom: 20px; width: 100%">
						<div class="title">
							{{ $t('report.desc4') }}
							<span class="zui-m-l-20" v-if="userInfo.reportStatus == 1" @click="add('list34')"><img
									class="imgClass" src="../assets/image/add.png" /></span>
						</div>
						<div>
							<el-table :data="table34" border style="width: 100%">
								<el-table-column width="70" align="center" header-align="center" prop="value1"
									:label="$t('report.th1')"></el-table-column>
								<el-table-column min-width="240" align="center" header-align="center" prop="value2"
									:label="$t('report.th30')"></el-table-column>
								<el-table-column min-width="120" align="center" header-align="center" prop="value3"
									:label="$t('report.th31')"></el-table-column>
								<el-table-column min-width="120" align="center" header-align="center" prop="value4"
									:label="$t('report.th32')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value5"
									:label="$t('report.th4')"></el-table-column>
								<el-table-column v-if="list34.length > 0 && userInfo.reportStatus == 1" width="70"
									align="center" header-align="center" :label="$t('report.operation')">
									<template slot-scope="scope">
										<img @click="edit('list34', scope.$index)" class="imgClass"
											src="../assets/image/12.png" />
										<img @click="del('list34', scope.$index)" class="imgClass"
											src="../assets/image/del.png" />
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="underline"></div>
					</div>
					<!-- AD/SB 执行情况 -->
					<div style="margin-bottom: 20px; width: 100%">
						<div class="title">
							{{ $t('report.desc5') }}
							<span class="zui-m-l-20" v-if="userInfo.reportStatus == 1" @click="add('list35')"><img
									class="imgClass" src="../assets/image/add.png" /></span>
						</div>
						<div>
							<el-table :data="table35" border style="width: 100%">
								<el-table-column width="70" align="center" header-align="center" prop="value1"
									:label="$t('report.th1')"></el-table-column>
								<el-table-column min-width="240" align="center" header-align="center" prop="value2"
									:label="$t('report.th20')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3"
									:label="$t('report.th5')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4"
									:label="$t('report.th6')"></el-table-column>
								<el-table-column min-width="120" align="center" header-align="center" prop="value5"
									:label="$t('report.th7')"></el-table-column>
								<el-table-column v-if="list35.length > 0 && userInfo.reportStatus == 1" width="70"
									align="center" header-align="center" :label="$t('report.operation')">
									<template slot-scope="scope">
										<img @click="edit('list35', scope.$index)" class="imgClass"
											src="../assets/image/12.png" />
										<img @click="del('list35', scope.$index)" class="imgClass"
											src="../assets/image/del.png" />
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="underline"></div>
					</div>
					<!-- 保留缺陷监控 -->
					<div style="margin-bottom: 20px; width: 100%">
						<div class="title">
							{{ $t('report.desc6') }}
							<span class="zui-m-l-20" v-if="userInfo.reportStatus == 1" @click="add('list36')"><img
									class="imgClass" src="../assets/image/add.png" /></span>
						</div>
						<div>
							<el-table :data="table36" border style="width: 100%">
								<el-table-column width="70" align="center" header-align="center" prop="value1"
									:label="$t('report.th1')"></el-table-column>
								<el-table-column min-width="240" align="center" header-align="center" prop="value2"
									:label="$t('report.th20')"></el-table-column>
								<el-table-column min-width="120" align="center" header-align="center" prop="value3"
									:label="$t('report.th8')"></el-table-column>
								<el-table-column min-width="120" align="center" header-align="center" prop="value4"
									:label="$t('report.th9')"></el-table-column>
								<el-table-column min-width="120" align="center" header-align="center" prop="value5"
									:label="$t('report.th10')"></el-table-column>
								<el-table-column v-if="list36.length > 0 && userInfo.reportStatus == 1" width="70"
									align="center" header-align="center" :label="$t('report.operation')">
									<template slot-scope="scope">
										<img @click="edit('list36', scope.$index)" class="imgClass"
											src="../assets/image/12.png" />
										<img @click="del('list36', scope.$index)" class="imgClass"
											src="../assets/image/del.png" />
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="underline"></div>
					</div>
					<!-- 结构损伤监控 -->
					<div style="margin-bottom: 20px; width: 100%">
						<div class="title">
							{{ $t('report.desc7') }}
							<span class="zui-m-l-20" v-if="userInfo.reportStatus == 1" @click="add('list39')"><img
									class="imgClass" src="../assets/image/add.png" /></span>
						</div>
						<div>
							<el-table :data="table39" border style="width: 100%">
								<el-table-column width="70" align="center" header-align="center" prop="value1"
									:label="$t('report.th1')"></el-table-column>
								<el-table-column min-width="240" align="center" header-align="center" prop="value2"
									:label="$t('report.th20')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3"
									:label="$t('report.th16')"></el-table-column>
								<el-table-column min-width="120" align="center" header-align="center" prop="value4"
									:label="$t('report.th37')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value5"
									:label="$t('report.th29')"></el-table-column>
								<el-table-column v-if="list39.length > 0 && userInfo.reportStatus == 1" width="70"
									align="center" header-align="center" :label="$t('report.operation')">
									<template slot-scope="scope">
										<img @click="edit('list39', scope.$index)" class="imgClass"
											src="../assets/image/12.png" />
										<img @click="del('list39', scope.$index)" class="imgClass"
											src="../assets/image/del.png" />
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="underline"></div>
					</div>
					<!-- 其它事项 -->
					<div style="margin-bottom: 20px; width: 100%">
						<div class="title">
							{{ $t('report.desc8') }}
							<!-- <span class="zui-m-l-20" v-if="userInfo.reportStatus == 1" @click="edit('list40')"><img class="imgClass" src="../assets/image/12.png" /></span> -->
							<span class="zui-m-l-20" v-if="userInfo.reportStatus == 1" @click="add('list40')"><img
									class="imgClass" src="../assets/image/add.png" /></span>
						</div>
						<div>
							<el-table :data="table40" border style="width: 100%">
								<el-table-column width="70" align="center" header-align="center" prop="value1"
									:label="$t('report.th1')"></el-table-column>
								<el-table-column min-width="240" align="center" header-align="center" prop="value2"
									:label="$t('report.th20')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3"
									:label="$t('report.th16')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4"
									:label="$t('report.th36')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value5"
									:label="$t('report.th29')"></el-table-column>
								<el-table-column v-if="list40.length > 0 && userInfo.reportStatus == 1" width="70"
									align="center" header-align="center" :label="$t('report.operation')">
									<template slot-scope="scope">
										<img @click="edit('list40', scope.$index)" class="imgClass"
											src="../assets/image/12.png" />
										<img @click="del('list40', scope.$index)" class="imgClass"
											src="../assets/image/del.png" />
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="underline"></div>
					</div>
					<!-- 账单 -->
					<div style="margin-bottom: 20px; width: 100%" >
						<div class="title">
							{{ $t('report.desc9') }}
							<span class="zui-m-l-20" v-if="userInfo.reportStatus == 1" @click="add('list37')"><img
									class="imgClass" src="../assets/image/add.png" /></span>
						</div>
						<div>
							<el-table :data="table37" stripe border style="width: 100%">
								<el-table-column width="70" align="center" header-align="center" prop="value1"
									:label="$t('report.th1')"></el-table-column>
									
									<el-table-column width="70" align="center" header-align="center" prop="value13"
										:label="$t('report.th46')"></el-table-column>
										
								<el-table-column min-width="240" align="center" header-align="center" prop="value2"
									:label="$t('report.th20')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3"
									:label="$t('report.th21')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4"
									:label="$t('report.th22')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value5"
									:label="$t('report.th23')"></el-table-column>
								<el-table-column min-width="150" align="center" header-align="center" prop="value6"
									:label="$t('report.th24')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value7"
									:label="$t('report.th25')"></el-table-column>
								<el-table-column min-width="150" align="center" header-align="center" prop="value8"
									:label="$t('report.th26')"></el-table-column>
								<el-table-column min-width="100" align="center" header-align="center" prop="value9"
									:label="$t('report.th27')"></el-table-column>
								<el-table-column min-width="160" align="center" header-align="center" prop="value10"
									:label="$t('report.th28')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value11"
									:label="$t('report.th29')"></el-table-column>
								<el-table-column v-if="list37.length > 0 && userInfo.reportStatus == 1" align="center"
									header-align="center" width="70" :label="$t('report.operation')">
									<template slot-scope="scope">
										<img @click="edit('list37', scope.$index)" class="imgClass"
											src="../assets/image/12.png" />
										<img @click="del('list37', scope.$index)" class="imgClass"
											src="../assets/image/del.png" />
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>

			</div>
			<!--endprint-->
			<!-- 评注 -->
			<div style="margin-bottom: 50px; width: 100%">
				<div class="title">{{ $t('report.desc12') }}</div>
				<div style="max-height: 300px;overflow-y: scroll;" class="zui-border zui-p-10">
					<div class="zui-flex-row zui-border-b zui-m-b-10" v-for="(item,index) in commentList" :key="index">
						<div class="zui-m-r-20">{{item.createTime}}</div>
						<div class="zui-flex-column">
							<div class="zui-m-b-10">
								{{item.userName}}<span>{{ $t('report.desc12') }}：
								</span><span>{{item.comment}}</span>
							</div>
							<div> <span>{{ $t('report.desc14') }}：</span><span>{{item.reply}}</span> </div>
							<div class="list33_font" v-if="item.userFile"><span>{{$t('report.mesg11')}}</span>
								<el-link v-for="(item,index) in setlink(item.userFile)" :key="index"
									:href="$apiUrl + '/sys/common/static/'+item.url" target="_blank" class="setlink"
									style="font-size: 18rpx" :underline="false" :title="$t('report.mesg10')+item.title">
									{{item.title}}
								</el-link>

							</div>
							<div class="list33_font" v-if="item.adminFile"><span>{{$t('report.mesg12')}}</span>
								<el-link v-for="(item,index) in setlink(item.adminFile)" :key="index"
									:href="$apiUrl + '/sys/common/static/'+item.url" target="_blank" class="setlink"
									style="font-size: 18rpx" :underline="false" :title="$t('report.mesg10')+item.title">
									{{item.title}}
								</el-link>
							</div>
						</div>
					</div>
				</div>
				<div class="zui-relative zui-m-t-10">
					<el-input type="textarea" :autofocus="true" :placeholder="$t('report.desc13')"
						v-model="commentForm.comment"></el-input>
					<div class="name_input zui-flex-column zui-m-t-10">
						<div class="title">{{$t('report.planeImg')}}</div>
						<el-upload ref="upload" :action="$apiUrl + '/sys/common/upload'" :data="{ biz: 'temp',isup:1 }"
							list-type="fileList" :on-success="uploadFileSuccess" :on-remove="handleRemove"
							:file-list="userFile" :limit="5">
							<el-button class="jinBtn" style="border-width: 0;" size="mini" type="primary">
								{{$t('report.upload')}}
							</el-button>
						</el-upload>
					</div>
					<div class="zui-flex-row zui-center-center zui-m-t-20">
						<el-button class="jinButton jinBorder btn" @click="comment" size="mini" type="primary">
							{{ $t('common.submit') }}
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<Message v-if="isLogin" ref="message" @list30="getList30" @list33="getList33" @list34="getList34"
			@list35="getList35" @list36="getList36" @list37="getList37" @list38="getList38" @list39="getList39"
			@list40="getList40" :titleType="titleType"></Message>
		<MessageAdd v-if="isLogin" ref="messageAdd" @list30="getList30" @list33="getList33" @list34="getList34"
			@list35="getList35" @list36="getList36" @list37="getList37" @list38="getList38" @list39="getList39"
			@list40="getList40" :titleType="titleType" :change_data="change_Date"></MessageAdd>
	</div>
</template>

<script>
	// import hangarTitle from '../components/hangarTitle';
	import htmlToPdf from '../util/htmlToPdf.js'
	import hangar from "../components/hangarCom";
	import signMd5Utils from '../assets/lib/signMd5Utils.js';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import areaObj from '../util/Area.js';
	import Message from '../components/usercenter/message.vue';
	import MessageAdd from '../components/usercenter/messageAdd.vue';
	import {
		ownerApi,
		ownerComment
	} from '../api/api';
	export default {
		components: {
			Message,
			// hangarTitle,
			hangar,
			MessageAdd
		},
		data() {
			return {
				isPhone: false,
				pdfPath: false,
				pdf: '导出PDF',
				jinBtn1: true,
				avatar: '../assets/image/LOGO.png',
				formData: {
					userName: '',
					email: '',
					loginName: '',
					avatar: '',
					user_photo: ''
				},
				desc: '',
				isInput: true,

				table30: [],
				table33: [],
				table34: [],
				table37: [],
				table35: [],
				table36: [],
				table38: [],
				table39: [],
				table40: [],
				searchDate: '',
				year: '',
				month: '',
				options: [],
				detail29_label: '',
				currentIndex: 0,
				currentId: 0,//飞机id
				reportId:'',//月报id
				detail29: {},
				list29: [],
				list30: [],
				list31: [],
				list32: [],
				list33: [],
				list34: [],
				list35: [],
				list36: [],
				list37: [],
				list38: [],
				list39: [],
				list40: [],
				titleType: 'list33',
				commentForm: {
					"fkOperationId": "",
					"comment": "",
					"userFile": ""
				},
				userFile: [],
				commentList: [],
				airUser: [],
				hide_el:true,
				change_Date:false
			};
		},
		watch: {
			language() {
				this.$nextTick(() => {
					this.pdf = this.$t('report.mesg8')
				})
			}
		},
		filters: {
			areaChange: function(value) {
				// console.log(value);

				return new areaObj().getText(value);
			},
			userAvatar: function(value) {
				// console.log(value);
				if ((value.search("http") != -1) && (value.search("https") != -1)) {
					return value
				} else {
					return this.$apiUrl + '/' + value
				}
			}
		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin', "language"]),
			isShowReport() {
				if (this.userInfo.userAuthority) {
					let isShow = this.userInfo.userAuthority.indexOf("hosting")
					return isShow != -1 ? true : false;
				} else {
					return false;
				}

			}
		},
		created: function() {
	
			if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
				this.isPhone = true
			} else {
				this.isPhone = false
			}
			if (this.isLogin == false) {
				this.$router.push({
					name: 'index'
				});
			}
			// console.log(this.userInfo);
			this.formData.userName = this.userInfo.userName;
			this.formData.email = this.userInfo.email;
			this.formData.loginName = this.userInfo.loginName;
			if (this.userInfo.userPhoto) {
				this.avatar = this.userInfo.userPhoto;
				this.formData.user_photo = this.userInfo.userPhoto;
			} else {
				this.avatar = require('../assets/image/11.png');
			}
			// console.log(this.userInfo);
			this.year = new Date().getFullYear();
			this.month = new Date().getMonth() + 1;
			this.searchDate = this.year + '-' + this.month;
		},
		beforeMount: function() {
			this.planeInfoByUserId();

		},
		methods: {
			...mapMutations(['logOut']),
			setlink(str) {
				var i, restr;
				var newarr = []
				if (str) {
					restr = str.split(',')
					for (i in restr) {
						let item = restr[i].replace('temp/', '')
						newarr.push({
							title: item,
							url: restr[i]
						})
					}
				}
				return newarr
			},
			edit(e, index) {
				this.titleType = e;
				let fonrData = {};
				fonrData.id =this.list31.id || this.reportId;
				fonrData[e] = [this.$data[e][index]]
				// fonrData.list30 = this.list30; //机身及发动机
				// fonrData.list32 = this.list32; //list32 统计时段
				// fonrData.list33 = this.list33; //list33 行程概要
				// fonrData.list34 = this.list34; //list34 当月维修工作
				// fonrData.list35 = this.list35; //list35 AD/BS执行情况
				// fonrData.list36 = this.list36; //list36 飞机故障/缺陷监控
				// fonrData.list37 = this.list37; //list37 账单
				// fonrData.list38 = this.list38; //航材
				// fonrData.list39 = this.list39; //结构损伤监控
				// fonrData.list40 = this.list40; // 其他事项
				this.$refs.message.showMask(fonrData)
				// this.$refs.message.showMask(this[e])
				console.log(fonrData)
			},
			add(e) {
				this.titleType = e;
				if (e == 'list30') {
					this.$refs.messageAdd.showMask(this.currentId)
				} else {
					console.log('月报id',this.reportId);
					console.log('月报id111',this.list31.id);
					this.$refs.messageAdd.showMask(this.list31.id || this.reportId, this.currentId, this.searchDate)
				}
				console.log(123456,this.list32,this.list34)
				
			},
			setEmpty() {
				
				this.list31 = [];
				this.list32 = [];
				this.list33 = [];
				this.list34 = [];
				this.list35 = [];
				this.list36 = [];
				this.list37 = [];
				this.list38 = [];
				this.list39 = [];
				this.list40 = [];

		
				this.table33 = [{
					value1: '-',
					value2: '-',
					value3: '-',
					value4: '-',
					value5: '-',
					value6: '-',
					value7: '-',
					value8: '-',
					value9: '-',
					value10: '-',
					value11: '-',
					value12: '-',
				}];
				this.table34 = [{
					value1: '-',
					value2: '-',
					value3: '-',
					value4: '-',
					value5: '-',
					value6: '-',
					value7: '-',
					value8: '-',
					value9: '-',
					value10: '-',
					value11: '-',
					value12: '-',
				}];
				this.table35 = [{
					value1: '-',
					value2: '-',
					value3: '-',
					value4: '-',
					value5: '-',
					value6: '-',
					value7: '-',
					value8: '-',
					value9: '-',
					value10: '-',
					value11: '-',
					value12: '-',
				}];
				this.table36 = [{
					value1: '-',
					value2: '-',
					value3: '-',
					value4: '-',
					value5: '-',
					value6: '-',
					value7: '-',
					value8: '-',
					value9: '-',
					value10: '-',
					value11: '-',
					value12: '-',
				}];
				this.table37 = [{
					value1: '-',
					value2: '-',
					value3: '-',
					value4: '-',
					value5: '-',
					value6: '-',
					value7: '-',
					value8: '-',
					value9: '-',
					value10: '-',
					value11: '-',
					value12: '-',
					value13: '-',
				}];
				this.table38 = [{
					value1: '-',
					value2: '-',
					value3: '-',
					value4: '-',
					value5: '-',
					value6: '-',
					value7: '-',
					value8: '-',
					value9: '-',
					value10: '-',
					value11: '-',
					value12: '-',
				}];
				this.table39 = [{
					value1: '-',
					value2: '-',
					value3: '-',
					value4: '-',
					value5: '-',
					value6: '-',
					value7: '-',
					value8: '-',
					value9: '-',
					value10: '-',
					value11: '-',
					value12: '-',
				}];
				this.table40 = [{
					value1: '-',
					value2: '-',
					value3: '-',
					value4: '-',
					value5: '-',
					value6: '-',
					value7: '-',
					value8: '-',
					value9: '-',
					value10: '-',
					value11: '-',
					value12: '-',
				}];
			},
			del(field, index) {
				this.$confirm(this.$t('report.mesg16'), this.$t('report.mesg17'), {
					confirmButtonText: this.$t('report.mesg18'),
					cancelButtonText: this.$t('report.mesg19'),
					type: 'warning'
				}).then(() => {
					this.handleDel(field, index)
				})
			},
			handleDel(field, index) {
				const _this = this;
				const delData = this.$data[field][index]
				// console.log(field + 'Del', delData)
				ownerApi[field + 'Del'](delData).then(res => {
					if (res.code == 200) {
						this.$message({
							message: this.$t('common.submitSuccess'),
							showClose: true,
							type: 'success',
							customClass: 'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								const methods = {
									list30: 'getList30',
									list33: 'getList33',
									list34: 'getList34',
									list35: 'getList35',
									list36: 'getList36',
									list37: 'getList37',
									list38: 'getList38',
									list39: 'getList39',
									list40: 'getList40'
								}
								_this[methods[field]](this.list31.id)
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});

						// console.log(res.message);
					}
				});
			},
			changeSelect(index = 0) {
				this.currentId = this.options[index].id;
				this.currentIndex = index;
				this.detail29_label = this.options[index].label;
				this.detail29 = this.list29[index];
				this.list30 = [];
				this.table30 = [{
					value1: '-',
					value2: '-',
					value3: '-',
					value4: '-',
					value5: '-',
					value6: '-',
					value7: '-',
					value8: '-',
					value9: '-',
					value10: '-',
					value11: '-',
					value12: '-',
				}];
				//托管机身及发动机小时数（根据 29、托管飞机基本信息ID获取）
				this.getList30(this.currentId);
				// 31、飞机运营月报基础信息（根据 29、托管飞机基本信息ID,年、月获取）
				this.getList31(this.currentId, this.year, this.month);
				// this.getComment()
			},
			changeDate: function() {
				this.reportId = '';
				// console.log(this.options);
				// console.log(this.detail29.id);
				let dateArr = this.searchDate.split('-');
				this.year = dateArr[0];
				this.month = dateArr[1];
				// console.log(dateArr);
				// 31、飞机运营月报基础信息（根据 29、托管飞机基本信息ID,年、月获取）
				this.getList31(this.detail29.id, this.year, this.month);
				// this.getComment()
				this.change_Date=!this.change_Date
			},
			//飞机获取
			planeInfoByUserId() {
				var _this = this;
				let queryData = {};
				// queryData.userId = 'f0019fdebedb443c98dcb17d88222c38';
				// queryData.userId = this.userInfo.id;
				queryData.userId = "*" + this.userInfo.id + "*";
				queryData.pageNo = 1;
				queryData.pageSize = 10;
				//获取数据
				this.axios
					.get(this.$apiUrl + '/airhostinginfo/airHostingInfo/list', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token,
							env: 'front'
						}
					})
					.then(res => {
						var data = res.data;

						if (data.code == 200) {
							if (data.result.total > 0) {
								this.list29 = data.result.records;
								//console.log(this.list29,'list29');
								this.$zui.each(data.result.records, (index, item) => {
									if (index == 0) {
										// _this.value = item.planeName
										this.detail29_label = item.planeType;
										this.currentId = item.id;
										this.detail29 = item;
									}
									let temp = {};
									temp.label = item.planeType;
									temp.value = index;
									temp.id = item.id;
									_this.options.push(temp);
								});
								// console.log(this.detail29);
								this.changeSelect(0);
							} else {
								this.$message({
									message: this.$t('report.mesg20'),
									showClose: true,
									type: 'error',
									customClass: 'hahaha',
									duration: 2000,
									offset: 20,
									onClose: () => {
										// this.logOut();
										// this.$router.push({
										// 	name: 'login'
										// });
									}
								});
							}
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
							// console.log(res.data.message);
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//机身及发动机
			getList30() {
				var _this = this;
				let queryData = {};
				queryData.id = this.currentId;
				// console.log(id);
				//获取数据
				this.axios
					.get(this.$apiUrl + '/airhostinginfo/airHostingInfo/queryAirHostingItemHoursByMainId', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token
						}
					})
					.then(res => {
					// console.log('list30', res.data);
					// console.log('list30', res.data.result);
					// console.log('list30', res.data.result.records);
						if (res.data.code == 200) {
							
							this.list30 = res.data.result.records;
							_this.table30 = [];
							this.$zui.each(this.list30, (index, item) => {
								if(index == 0){
									this.reportId = item.fkOperationId
									console.log(this.reportId);
								}
								let temp = {};
								temp.value1 = item.itemName;
								temp.value2 = item.itemType;
								temp.value3 = item.totalHours;
								temp.value4 = item.totalCyclesNum;
								_this.table30.push(temp)

							});
							//console.log(this.list30)
							//console.log(this.table30)
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
							console.log(res.data);
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//月报获取
			getList31(id, airYear, airMonth) {
				if (id == undefined) {
					return false;
				}
				var _this = this;
				let queryData = {};
				queryData.hostingInfoId = id;
				queryData.airYear = airYear;
				queryData.airMonth = airMonth;
				// queryData.airMonth = 10;
				queryData.pageNo = 1;
				queryData.pageSize = 10;
				//获取数据

				this.axios
					.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/list', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token
						}
					})
					.then(res => {
						// console.log(res.data);
						this.commentList = [];
						if (res.data.code == 200) {
							if (res.data.result.total > 0) {
								this.list31 = res.data.result.records[0];
								//32、运营统计时段 （根据 31、飞机运营月报基础信息ID获取）
								this.getList32(this.list31.id);
								// 33、行程概要 （根据 31、飞机运营月报基础信息ID获取）
								this.getList33(this.list31.id);
								// 34、当月维修工作 （根据 31、飞机运营月报基础信息ID获取）
								this.getList34(this.list31.id);
								// 35、AD-BS执行情况 （根据 31、飞机运营月报基础信息ID获取）
								this.getList35(this.list31.id);
								// 36、飞机故障\缺陷监控 （根据 31、飞机运营月报基础信息ID获取）
								this.getList36(this.list31.id);
								// 37、备用金额剩余情况 （根据 31、飞机运营月报基础信息ID获取）
								this.getList37(this.list31.id);
								// 38、获取航材 （根据 31、飞机运营月报基础信息ID获取）
								this.getList38(this.list31.id);
								// 39、结构损伤（根据 31、飞机运营月报基础信息ID获取）
								this.getList39(this.list31.id);
								// 40、其他事项（根据 31、飞机运营月报基础信息ID获取）
								this.getList40(this.list31.id);
								// 获取评注
								// console.log('评注')
								this.getComment();
							} else {
								this.$message({
									message: airMonth + this.$t('report.mesg1'),
									showClose: true,
									type: 'error',
									customClass: 'hahaha',
									duration: 2000,
									offset: 20
								});
								this.setEmpty();
							}
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});

							// console.log(res.data.message);
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//行程统计
			getList32(id) {
				var _this = this;
				let queryData = {};
				queryData.id = id;
				//获取数据
				this.axios
					.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirHoursCountByMainId', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token
						}
					})
					.then(res => {
						// console.log(res.data);
						if (res.data.code == 200) {
							this.list32 = res.data.result.records;
							// console.log(this.list32,'list32');
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//行程概要
			getList33(id) {
				var _this = this;
				let queryData = {};
				queryData.id = id;
				//获取数据
				this.axios
					.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirTourSummaryByMainId', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token
						}
					})
					.then(res => {
						console.log('list33', res.data);
						if (res.data.code == 200) {
							this.list33 = res.data.result.records;
							if (this.list33.length == 0) {
								this.table33 = [{
									value1: '-',
									value2: '-',
									value3: '-',
									value4: '-',
									value5: '-',
									value6: '-',
									value7: '-',
									value8: '-',
									value9: '-',
									value10: '-',
									value11: '-',
									value12: '-',
								}];
							} else {
								_this.table33 = [];
								this.$zui.each(this.list33, (index, item) => {
									if(index == 0){
										this.reportId = item.fkOperationId
										console.log(this.reportId);
									}
									let temp = {};
									temp.value1 = item.dateDay;
									temp.value2 = item.startFly;
									temp.value3 = item.endDateDay;
									temp.value4 = item.endFly;
									temp.value5 = item.countHours;
									temp.value6 = item.remark;
									temp.fkOperationId = item.fkOperationId;
									_this.table33.push(temp)

								});
							}
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//当月维修
			getList34(id) {
				var _this = this;
				let queryData = {};
				queryData.id = id;
				//获取数据
				this.axios
					.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirRepairDetailByMainId', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token
						}
					})
					.then(res => {
						// console.log('维护',res.data);
						if (res.data.code == 200) {
							this.list34 = res.data.result.records;
							if (this.list34.length == 0) {
								this.table34 = [{
									value1: '-',
									value2: '-',
									value3: '-',
									value4: '-',
									value5: '-',
									value6: '-',
									value7: '-',
									value8: '-',
									value9: '-',
									value10: '-',
									value11: '-',
									value12: '-',
								}];
							} else {
								_this.table34 = [];
								this.$zui.each(this.list34, (index, item) => {
									if(index == 0){
										this.reportId = item.fkOperationId
										console.log(this.reportId);
									}
									let temp = {};
									temp.value1 = Number(index) + 1;
									temp.value2 = item.repairDateBegin + '~' + item.repairDateEnd;
									temp.value3 = item.repairLevel;
									temp.value4 = item.repairProject;
									temp.value5 = item.remake;
									temp.fkOperationId = item.fkOperationId;
									_this.table34.push(temp)

								});
							}

							// console.log(_this.table34);
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//AD/SB 执行情况
			getList35(id) {
				var _this = this;
				let queryData = {};
				queryData.id = id;
				//获取数据
				this.axios
					.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirAdSbDetailByMainId', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token
						}
					})
					.then(res => {
						// console.log(res.data);
						if (res.data.code == 200) {
							this.list35 = res.data.result.records;
							if (this.list35.length == 0) {
								this.table35 = [{
									value1: '-',
									value2: '-',
									value3: '-',
									value4: '-',
									value5: '-',
									value6: '-',
									value7: '-',
									value8: '-',
									value9: '-',
									value10: '-',
									value11: '-',
									value12: '-',
								}];
							} else {
								_this.table35 = [];
								this.$zui.each(this.list35, (index, item) => {
									if(index == 0){
										this.reportId = item.fkOperationId
										console.log(this.reportId);
									}
									let temp = {};
									temp.value1 = Number(index) + 1;
									temp.value2 = item.endDateDay == null ? item.dateDay : item.dateDay + '~' +
										item.endDateDay;
									temp.value3 = item.serialNum;
									temp.value4 = item.planeDescribe;
									temp.value5 = item.progressContent;
									temp.fkOperationId = item.fkOperationId;
									_this.table35.push(temp)
								});
							}

						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//飞机故障/缺陷监控
			getList36(id) {
				var _this = this;
				let queryData = {};
				queryData.id = id;
				//获取数据
				this.axios
					.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirFaultMonitoringByMainId', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token
						}
					})
					.then(res => {
						// console.log(res.data);
						if (res.data.code == 200) {
							this.list36 = res.data.result.records;
							if (this.list36.length == 0) {
								this.table36 = [{
									value1: '-',
									value2: '-',
									value3: '-',
									value4: '-',
									value5: '-',
									value6: '-',
									value7: '-',
									value8: '-',
									value9: '-',
									value10: '-',
									value11: '-',
									value12: '-',
								}];
							} else {
								_this.table36 = [];
								this.$zui.each(this.list36, (index, item) => {
									if(index == 0){
										this.reportId = item.fkOperationId
										console.log(this.reportId);
									}
									let temp = {};
									temp.value1 = Number(index) + 1;
									temp.fkOperationId = item.fkOperationId;
									temp.value2 = item.endFaultDate == null ? item.faultDate : item.faultDate +
										'~' + item.endFaultDate;

									temp.value3 = item.level;
									temp.value4 = item.faultDesc;
									temp.value5 = item.faultReason;
									temp.value6 = item.faultDate;
									_this.table36.push(temp);
								});
							}

						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//账单
			getList37(id) {
				var _this = this;
				let queryData = {};
				queryData.id = id;
				//获取数据
				this.axios
					.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirMoneyRemainingContentByMainId', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token
						}
					})
					.then(res => {
						// console.log('剩余',res.data);
						if (res.data.code == 200) {
							this.list37 = res.data.result.records;
							if (this.list37.length == 0) {
								this.table37 = [{
									value1: '-',
									value2: '-',
									value3: '-',
									value4: '-',
									value5: '-',
									value6: '-',
									value7: '-',
									value8: '-',
									value9: '-',
									value10: '-',
									value11: '-',
									value12: '-',
									value13: '-',
								}];
							} else {
								_this.table37 = [];
								this.$zui.each(this.list37, (index, item) => {
									let temp = {};
									temp.value1 = Number(index) + 1;
									temp.value2 = item.endBillTime == null ? item.billTime : item.billTime +
										'~' + item.endBillTime;

									temp.value3 = item.leg;
									temp.value4 = item.project;
									temp.value5 = item.detail;
									temp.value6 = item.amount;
									temp.value7 = item.exchangeRate;
									temp.value8 = item.amountRmb;
									temp.value9 = item.taxRate;
									temp.value10 = item.serviceCharge;
									temp.value11 = item.remark;
									temp.value13 = item.serialNumber;
									temp.id = item.id;
									temp.fkOperationId = item.fkOperationId;
									_this.table37.push(temp)
								});
							}

							// console.log(_this.table37);
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//航材
			getList38(id) {
				var _this = this;
				let queryData = {};
				queryData.id = id;
				//获取数据
				this.axios
					.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirMaterialStorageByMainId', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token
						}
					})
					.then(res => {
						// console.log('list38', res.data);
						if (res.data.code == 200) {
							this.list38 = res.data.result;
							if (this.list38.length == 0) {
								this.table38 = [{
									value1: '-',
									value2: '-',
									value3: '-',
									value4: '-',
									value5: '-',
									value6: '-',
									value7: '-',
									value8: '-',
									value9: '-',
									value10: '-',
									value11: '-',
									value12: '-',
								}];
							} else {
								_this.table38 = [];
								this.$zui.each(this.list38, (index, item) => {
									let temp = {};
									temp.value1 = Number(index) + 1;
									temp.fkOperationId = item.fkOperationId;
									temp.value2 = item.project;
									temp.value3 = item.partNumberPn;
									temp.value4 = item.partNumberSn;
									temp.value5 = item.inventory;
									temp.value6 = item.endPeriodOfValidity == null ? item.periodOfValidity :
										item.periodOfValidity + '~' + item.endPeriodOfValidity;
									temp.value7 = item.remark;
									temp.value8 = item.amount;
									_this.table38.push(temp)
								});
							}

							// console.log(_this.table37);
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//结构损伤
			getList39(id) {
				var _this = this;
				let queryData = {};
				queryData.id = id;
				//获取数据
				this.axios
					.get(this.$apiUrl +
						'/airhostingoperation/airHostingOperation/queryAirHostingOperationMonitoringByMainId', {
							params: queryData,
							headers: {
								'X-Access-Token': _this.token
							}
						})
					.then(res => {
						// console.log('list39', res.data);
						if (res.data.code == 200) {
							this.list39 = res.data.result.records;

							if (this.list39.length == 0) {
								this.table39 = [{
									value1: '-',
									value2: '-',
									value3: '-',
									value4: '-',
									value5: '-',
									value6: '-',
									value7: '-',
									value8: '-',
									value9: '-',
									value10: '-',
									value11: '-',
									value12: '-',
								}];
							} else {
								_this.table39 = [];
								this.$zui.each(this.list39, (index, item) => {
									let temp = {};
									temp.value1 = Number(index) + 1;
									temp.value2 = item.endDateDay == null ? item.dateDay : item.dateDay + '~' +
										item.endDateDay;

									temp.value3 = item.monitoringProject;
									temp.value4 = item.monitoringNum;
									temp.value5 = item.remark;
									temp.id = item.id;
									temp.fkOperationId = item.fkOperationId;
									_this.table39.push(temp)
								});
							}

							// console.log(_this.table37);
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			//其它
			getList40(id) {
				var _this = this;
				let queryData = {};
				queryData.id = id;
				//获取数据
				this.axios
					.get(this.$apiUrl +
						'/airhostingoperation/airHostingOperation/queryAirHostingOperationOthersByMainId', {
							params: queryData,
							headers: {
								'X-Access-Token': _this.token
							}
						})
					.then(res => {
						// console.log('剩余',res.data);
						if (res.data.code == 200) {
							this.list40 = res.data.result.records;
							if (this.list40.length == 0) {
								this.table40 = [{
									value1: '-',
									value2: '-',
									value3: '-',
									value4: '-',
									value5: '-',
									value6: '-',
									value7: '-',
									value8: '-',
									value9: '-',
									value10: '-',
									value11: '-',
									value12: '-',
								}];
							} else {
								_this.table40 = [];
								this.$zui.each(this.list40, (index, item) => {
									let temp = {};
									temp.value1 = Number(index) + 1;
									temp.value2 = item.endOtherDate == null ? item.otherDate : item.otherDate +
										'~' + item.endOtherDate;

									temp.value3 = item.otherProject;
									temp.value4 = item.otherContent;
									temp.value5 = item.remark;
									temp.id = item.id;
									temp.fkOperationId = item.fkOperationId;
									_this.table40.push(temp)
								});
							}

							// console.log(_this.table37);
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								duration: 2000,
								customClass: 'hahaha',
								offset: 20
							});

						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			inputEvn() {
				this.isInput = !this.isInput;
			},
			editUser() {
				//定义表单规则
				var rule = [{
						name: 'userName',
						checkType: 'notnull',
						checkRule: '',
						errorMsg: this.$t('report.realname')
					},
					{
						name: 'email',
						checkType: 'email',
						checkRule: '',
						errorMsg: this.$t('report.email')
					}
				];
				if (!this.$zuiChecker.check(this.formData, rule)) {
					this.$message({
						message: this.$zuiChecker.error,
						showClose: true,
						type: 'error',
						customClass: 'hahaha',
						duration: 2000,
						offset: 20
					});
					return;
				}
				let _this = this;
				let url = this.$apiUrl + '/front/editUserInfo';
				let sign = signMd5Utils.getSign(url, this.formData);
				let timestamp = signMd5Utils.getDateTimeToString();
				// console.log(this.formData);
				this.axios
					.put(url, this.formData, {
						headers: {
							'X-Access-Token': _this.token,
							'X-Sign': sign,
							'X-TIMESTAMP': timestamp
						}
					})
					.then(res => {
						// console.log(res);
						if (res.data.code == 200) {
							let userData = this.userInfo;
							userData.avatar = this.formData.avatar;
							userData.user_photo = this.formData.user_photo;
							userData.userPhoto = this.formData.user_photo;
							userData.userName = this.formData.userName;
							userData.phone = this.formData.phone;
							userData.email = this.formData.email;
							// console.log(userData);
							this.$store.dispatch('saveUserInfo', userData);
							this.$message({
								message: this.$t('report.mesg2'),
								showClose: true,
								type: 'success',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20,
							});
							this.inputEvn();
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								offset: 20,
								duration: 2000,
							});
							// console.log(res.message);
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('report.token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			handleAvatarSuccess(res, file) {
				console.log(res, file);
				this.inputEvn();
				if (res.code == 0) {
					this.formData.avatar = res.message;
					this.formData.user_photo = res.message;
				}
				this.avatar = res.message;
				// this.avatar = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt2M) {
					// this.$message.error('上传头像图片大小不能超过 2MB!');
					this.$message({
						message: this.$t('report.mesg3'),
						type: 'error',
						customClass: 'hahaha',
						duration: 2000,
					})
				}
				return isLt2M;
			},
			chageDate() {
				this.$refs.date.focus()
			},
			//提交 评注
			comment() {
				if (!this.currentId) {
					this.$message({
						message: this.$t('report.mesg4'),
						showClose: true,
						type: 'error',
						customClass: 'hahaha',
						offset: 20,
						duration: 2000,
					});
					return false;
				}
				var rule = [{
					name: "comment",
					checkType: "notnull",
					checkRule: "",
					errorMsg: this.$t('report.mesg5')
				}, ];
				var rule1 = [{
					name: "userFile",
					checkType: "notnull",
					checkRule: "",
					errorMsg: this.$t('report.mesg6')
				}, ];

				if (!this.$zuiChecker.check(this.commentForm, rule) && !this.$zuiChecker.check(this.commentForm, rule1)) {
					this.$message({
						message: this.$t('report.mesg7'),
						showClose: true,
						type: 'error',
						customClass: 'hahaha',
						duration: 2000,
						offset: 20
					});
					return;
				}
				// this.commentForm.fkOperationId = this.currentId
				this.commentForm.fkOperationId = this.list31.id
				ownerComment['add'](this.commentForm, {
					hostingDate: this.searchDate,
					hostingInfoId: this.currentId,
				}).then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.$message({
							message: this.$t('common.submitSuccess'),
							showClose: true,
							type: 'success',
							customClass: 'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								this.getComment();
								this.userFile = [];
								this.commentForm.comment = '',
									this.commentForm.userFile = ''
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			getComment() {
				// console.log('月报',this.list31.id);
				if (this.list31.id == undefined || !this.list31.id) {
					this.commentList = [];
					
					return;
				}
				
				let queryData = {};
				queryData.id = this.list31.id
				// queryData.id = this.currentId
				// console.log(this.currentId);
				ownerComment['get'](queryData, {
					hostingDate: this.searchDate,
					hostingInfoId: this.currentId,
				}).then(res => {
					// console.log(res,123456);
					if (res.code == 200) {
						// console.log(res.result.records);
						if (res.result.total > 0) {
							this.commentList = res.result.records;
						}
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			uploadFileSuccess(response, fileList) {
				// console.log(response, fileList);
				if (response.code == 0) {
					this.userFile.push(fileList);
					if (this.userFile.length > 1) {
						let userFile = this.commentForm.userFile.split(',')
						userFile.push(response.message)
						this.commentForm.userFile = userFile.join(',');
					} else {
						this.commentForm.userFile = response.message;
					}
				}
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.userFile = fileList;
				let userFile = [];
				this.$zui.each(fileList, function(k, item) {
					userFile.push(item.response.message)
				})
				this.commentForm.userFile = userFile.join(',');
			},
			getPdfEvn() {
				this.pdf = this.$t('report.mesg9');
				this.jinBtn1 = false
				this.hide_el=false
				// console.log(this.searchDate)
				this.htmlPdfTitle = this.searchDate + ' ' + this.$t('report.title2')
				setTimeout( ()=>{
				htmlToPdf.downloadPDF(
					document.querySelector('#pdfDom'),
					this.htmlPdfTitle,document.querySelectorAll(".is-scrolling-left"),
					document.querySelectorAll(".content"),
					document.querySelectorAll(".el-table__header-wrapper>table"),
					document.querySelectorAll(".el-table__body-wrapper>table"),
					document.querySelector(".jinBtn1"),
					
					);
					}
				,100)
				setTimeout( ()=>{
					this.hide_el=true
				},150)
				
				//this.jinBtn1=true
			}

		}
	};
</script>

<style lang="scss" scoped>
	#pdfDom{
		width: 100%;
	}
	.en_state {
		width: auto !important;
	}

	.jinBtn1 {

		height: 26px;
		padding: 4px ;
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		background-color: #000000;
		color: #DBB96E;
		// margin: 0 auto 15px;
		cursor: pointer;
		text-align: center;

		&:hover {
			background-color: #DBB96E;
			color: #000000;
		}
	}

	.btn222 {
		margin-top: 10px;
	}

	.setlink {
		display: block;
		font-size: 0.266rem;
		color: #1890ff;
	}

	.imgClass {
		width: 15px;
		height: 15px;
		vertical-align: inherit;
		margin: 0 5px;
		cursor: pointer;
	}

	.list33_font {
		font-size: 18rpx;
	}

	.avatar {
		width: 60px;
		height: 60px;
	}

	.page {
		font-size: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.info-box {
			padding: 10px;

			div {
				font-size: 14px !important;
				color: white !important;
			}
		}

		.justify {
			text-align: justify;
			width: 70px;
			font-size: 15px;
			height: 18px;
		}

		.justify>span {
			display: inline-block
				/* Opera */
			;
			padding-left: 100%;
		}

		.content {
			margin-top: 20px;
			font-size: 10px;
			// max-width: 967px;
			width: 80%;
			font-family: Microsoft YaHei;
			line-height: 21px;
			font-weight: 400;
		}

		.btn {
			// position: absolute;
			bottom: 5%;
			right: 2%;
			height: 30px;
			padding: 0;
			margin-top: 10px;
		}

		.theme {
			border-bottom: 1px solid #000;
			width: 100%;
			height: 50px;
			position: relative;
			margin-bottom: 40px;

			.title-box {
				position: absolute;
				bottom: -15px;
				left: 50%;
				transform: translateX(-50%);
				// background: white;

				.title-text {
					font-size: 14px;
					text-align: center;
					background: black;
					color: white;
					padding: 6px 25px;
					z-index: 2;
					height: 100%;
					box-sizing: border-box;
				}
			}
		}

		.base-info {
			display: flex;
			flex-direction: row;
			margin-bottom: 20px;
			width: 100%;
			justify-content: space-around;

			.item-box {
				// flex: 1;
				display: flex;
				flex-direction: column;
			}

			.item {

				flex: 1;
				display: flex;
				flex-direction: row;
				margin-bottom: 10px;
				justify-content: center;

				.labelBox {
					text-align-last: justify;
					width: 66px;
					display: inline-block;
				}

			}
		}

		.underline {
			margin-top: 20px;
			border-bottom: 1px dotted #000;
		}

		.title {
			margin-bottom: 10px;
			font-size: 18px;
			font-weight: bold;
			color: #000000;
		}

		.remark {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #f3f3f3;
			min-height: 40px;
		}

		.table-head {
			background-color: #000;
		}

		.el-table {
			font-size: 12px;
		}
	}

	/deep/.userChange .el-input.is-disabled .el-input__inner {
		background-color: transparent;
		border-color: transparent;
		color: white;
	}

	/deep/.userChange .el-input__inner {
		background-color: transparent !important;
		color: white !important;
	}

	/deep/.changeBox .el-input.is-disabled .el-input__inner {
		background-color: transparent;
		border-color: transparent;
		color: black !important;
	}

	/deep/.changeBox .el-input__inner {
		background-color: transparent !important;
		color: black !important;
	}

	/deep/ .cell {
		color: black !important;
		font-size: 12px;
	}

	.userBtn {
		padding: 3px 8px;
		font-size: 12px;
		color: white;
		background-color: #dea832;
	}

	@media screen and (max-width: 960px) {
		.avatar {
			width: 60px;
			height: 60px;
		}

		/deep/ .base-info {
			flex-direction: column !important;
		}

		.list33_font {
			font-size: 12rpx;
		}

		.page {
			.base-info {
				width: 100%;
			}

			.content {
				padding: 0 5px;
				margin-top: 20px;
				font-size: 10px;
				width: 100%;
				font-family: Microsoft YaHei;
				line-height: 21px;
				font-weight: 400;
			}
		}
	}
</style>
